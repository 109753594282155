.navbar {
    &.fixed-top {
        +.page-body-wrapper {
            padding-top: $navbar-height;
        }
    }
}


// Sidebar Mini
.sidebar-mini {
    @media (min-width: 992px) {
        .navbar {
            .navbar-brand-wrapper {
                width: $sidebar-width-mini;
            }

            .navbar-menu-wrapper {
                width: calc(100% - #{$sidebar-width-mini});
            }
        }

        .sidebar {
            width: $sidebar-width-mini;

            .nav {
                .nav-item {
                    .nav-link {
                        display: flex;
                        align-content: center;
                        justify-content: center;
                        flex-direction: column;
                        position: relative;

                        &:before {
                            display: none;
                        }

                        .menu-title {
                            display: inline-block;
                        }

                        .badge {
                            margin-left: 5px;
                            display: none;
                        }

                        img {
                            display: block;
                            margin-right: auto;
                            margin-left: auto;
                            margin-bottom: 10px;
                        }

                        .menu-arrow {
                            position: absolute;
                            right: 7px;
                        }

                        .menu-icon {
                            margin-right: 0;
                        }
                    }
                }
            }

            >.nav {
                &:not(.sub-menu) {
                    >.nav-item {
                        &:hover {
                            &:not(.nav-profile):not(.hover-open) {
                                >.nav-link:not([aria-expanded="true"]) {
                                    padding-left: 0px;

                                    .rtl & {
                                        padding-left: 0px;
                                        padding-right: 0px;
                                    }

                                    &:hover {
                                        padding-left: 0px;
                                        padding-right: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .main-panel {
            width: calc(100% - #{$sidebar-width-mini});
        }

        &:not(.sidebar-icon-only) {

            //Apply these styles only when sidebar-mini is not collapsed to icon-only mode
            .sidebar {
                .nav {
                    &:not(.sub-menu) {
                        >.nav-item {
                            border-top: 1px solid rgba($sidebar-dark-menu-color, .2);

                            &.nav-profile {
                                .nav-link {
                                    .profile-image {
                                        margin-right: 0;
                                    }

                                    .dot-indicator {
                                        bottom: 10px;
                                    }

                                    .text-wrapper {
                                        text-align: center;
                                    }
                                }
                            }

                            &.nav-category {
                                display: none;
                            }

                            .nav-link {
                                height: auto;
                                padding: $sidebar-mini-menu-padding;

                                i {
                                    &.menu-icon {
                                        margin-bottom: 0.5rem;
                                    }
                                }
                            }
                        }
                    }

                    .nav-item {

                        .collapse,
                        .collapsing,
                        .collapse.show {
                            .sub-menu {
                                padding: 0 0.5rem;

                                .nav-item {
                                    .nav-link {
                                        padding: 10px 0;
                                        text-align: center;

                                        &:before {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Sidebar Icon Only
.sidebar-icon-only {
    @media (min-width: 992px) {
        .navbar {
            .navbar-brand-wrapper {
                width: $sidebar-width-icon;

                .brand-logo {
                    display: none;
                }

                .brand-logo-mini {
                    display: flex;
                }
            }

            .navbar-menu-wrapper {
                width: calc(100% - #{$sidebar-width-icon});
            }
        }

        .sidebar {
            width: $sidebar-width-icon;

            .nav {
                overflow: visible;

                .nav-item {
                    position: relative;

                    .nav-link {
                        display: block;
                        text-align: center;
                        padding-left: 25px;
                        padding-right: 25px;

                        .badge,
                        .menu-title,
                        &:before {
                            display: none;
                        }

                        i {
                            &.menu-icon {
                                display: inline-block;
                                margin-right: 0;
                                font-size: 22px;
                            }

                            &.menu-arrow {
                                display: none;
                            }
                        }
                    }

                    &.nav-profile,
                    &.nav-category {
                        display: none;
                    }

                    .collapse {
                        display: none;
                    }

                    &.hover-open {
                        .nav-link {
                            background: $sidebar-dark-menu-hover-bg;

                            .menu-title {
                                display: flex;
                                align-items: center;
                                background: $sidebar-dark-menu-hover-bg;
                                color: $sidebar-dark-menu-color;
                                padding: 12px 0 10px 25px;
                                left: $sidebar-width-icon;
                                position: absolute;
                                text-align: left;
                                top: 0;
                                width: $sidebar-icon-only-submenu-width;
                                z-index: 1;
                                height: $nav-link-height;
                                line-height: 1.8;
                                border-top-right-radius: 5px;
                                border-bottom-right-radius: 5px;

                                @at-root #{selector-append(".rtl", &)} {
                                    padding: 12px 25px 10px 0px;
                                    left: auto;
                                    right: $sidebar-width-icon;
                                    text-align: right;
                                    border-top-right-radius: 0;
                                    border-bottom-right-radius: 0;
                                    border-top-left-radius: 5px;
                                    border-bottom-left-radius: 5px;
                                }

                                &:after {
                                    display: none;
                                }
                            }

                            &[data-toggle=collapse] {
                                .menu-title {
                                    border-bottom-right-radius: 0;

                                    @at-root #{selector-append(".rtl", &)} {
                                        border-bottom-left-radius: 0;
                                    }
                                }
                            }
                        }

                        .collapse,
                        .collapse,
                        .collapsing {
                            display: block;
                            padding: 0;
                            background: $sidebar-light-menu-hover-bg;
                            color: $sidebar-light-menu-color;
                            position: absolute;
                            top: $nav-link-height;
                            left: $sidebar-width-icon;
                            width: $sidebar-icon-only-submenu-width;
                            border-bottom-right-radius: 5px;
                            box-shadow: $sidebar-icon-only-collapse-shadow;

                            @at-root #{selector-append(".rtl", &)} {
                                border-bottom-right-radius: 0;
                                border-bottom-left-radius: 5px;
                            }

                            @at-root #{selector-append(".rtl", &)} {
                                left: auto;
                                right: $sidebar-width-icon;
                            }
                        }
                    }
                }

                &.sub-menu {
                    .nav-item {
                        .nav-link {
                            text-align: left;
                            color: $sidebar-light-menu-color;

                            &:before {
                                display: none;
                            }

                            &:hover {
                                color: darken($white, 20%);
                            }

                            @at-root #{selector-append(".rtl", &)} {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }

        .main-panel {
            width: calc(100% - #{$sidebar-width-icon});
        }
    }
}

// Hidden Sidebar
.sidebar-hidden {
    @media (min-width: 992px) {
        .sidebar {
            transition: width $action-transition-duration $action-transition-timing-function;
            -webkit-transition: width $action-transition-duration $action-transition-timing-function;
            -moz-transition: width $action-transition-duration $action-transition-timing-function;
            -ms-transition: width $action-transition-duration $action-transition-timing-function;
            width: 0;
        }

        .main-panel {
            width: 100%;
        }
    }
}

// Absolute sidebar with overlay to content
.sidebar-absolute {
    @media (min-width: 992px) {
        .page-body-wrapper {
            position: relative;

            .sidebar {
                transition: none;
            }
        }

        &:not(.sidebar-hidden) {
            .sidebar {
                position: absolute;
                height: 100%;
                box-shadow: $sidebar-box-shadow;
            }
        }

        .main-panel {
            width: 100%;
            transition: none;
        }
    }
}

//Fixed sidebar
.sidebar-fixed {
    @media(min-width: 992px) {
        .sidebar {
            position: fixed;
            max-height: auto;

            .nav {
                max-height: calc(100vh - #{$navbar-height});
                overflow: auto;
                position: relative;

                &.sub-menu {
                    max-height: none;
                }
            }
        }

        .main-panel {
            margin-left: $sidebar-width-lg;
        }

        &.sidebar-icon-only {
            .main-panel {
                margin-left: $sidebar-width-icon;
            }
        }
    }
}

//Boxed layout
.boxed-layout {
    @media (min-width: 992px) {
        background: $boxed-layout-bg;

        .container-scroller {
            width: 100%;
            max-width: $boxed-container-width;
            margin-left: auto;
            margin-right: auto;
        }

        .navbar {
            width: 100%;
            max-width: $boxed-container-width;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

//RTL layout
.rtl {
    direction: rtl;
    text-align: right;

    .sidebar {
        .nav {
            padding-right: 0;

            .nav-item {
                .nav-link {
                    i {
                        &.menu-icon {
                            margin-right: 0;
                            margin-left: 0.5em;
                        }

                        &.menu-arrow {
                            margin-left: 0;
                            margin-right: auto;
                        }
                    }

                    .badge {
                        margin-left: 0;
                        margin-right: auto;
                    }
                }
            }
        }
    }

    .product-chart-wrapper,
    .settings-panel .tab-content .tab-pane .scroll-wrapper,
    .sidebar-fixed .nav,
    .table-responsive,
    ul.chats {
        &::-webkit-scrollbar {
            width: 0.5em;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }
    }
}